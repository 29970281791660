.WelcomeBox{
    background-color: black;
    /* background-image: url("../../images/snack-banner-2.jpeg"); */
    background-repeat: no-repeat; 
    background-size: calc(100%) -200px;
    background-position:fixed;
}
.WelcomeOpacity{
    opacity: .8;
}
.test {
    font-family: 'Acme', sans-serif;
    text-align: center;
    font-size: 3rem;
}