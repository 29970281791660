*{
    box-sizing: border-box;
}
.menu-title{
    font-family: 'Acme', sans-serif;
}

.menu-link{
    cursor: pointer;
    padding: 1rem;
    color: black;
    border-bottom: 0.4rem solid white;;
    text-decoration: none;
}

.menu-link:hover{
    border-bottom: 0.4rem solid #ab20fd;;
}

.sub-menu:hover {
    color: black;
    background-color: #CBC3E3;
}

.dropdown-link {
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    background-color: white;
    text-decoration: none;
    color: black;
}

.dropdown-link:hover {
    background-color: #F7EAFF
}

.dropdown-link:last-child {
    box-shadow: 0px 5px 4px -1px rgba(0,0,0,0.4);
}

.active {
    color: #ab20fd;
    text-decoration: none;
}

.brand-icon:hover {
    animation: mymove 1s both ;
}

@media screen and (min-width: 600px) {
    @keyframes mymove {
        0%{font-size: 2rem;}
        100% {font-size: 2.15rem;}
    }
}

@media screen and (max-width: 600px) {
    @keyframes mymove {
        0%{font-size: 1.5rem;}
        100% {font-size: 1.7rem;}
    }
}

@media screen and (max-width: 400px) {
    @keyframes mymove {
        0%{font-size: 1.30rem;}
        100% {font-size: 1.42rem;}
    }
}
@media screen and (min-width: 1536) {
    h3 {
        font-size: 2rem;
    }
}