*{
   box-sizing: border-box; 
}
.snack-background{
    background-color: black;
    background-image: url("../../../public/Images/vending-snacks.jpg");
    background-size: contain;
    opacity: 1;
    color: white;
    box-shadow: 0rem .25rem 1rem black;
}

.snack-background:hover{
    color: #ab20fd;
    box-shadow: 3px 3px 1em #ab20fd;
}

.drink-background{
    background-color: black;
    background-image: url("../../../public/Images/Vending-drinks.jpg");
    background-size: cover;
    opacity: 1;
    color: white;
    box-shadow: 0rem .25rem 1rem black;
}

.drink-background:hover{
    color: #ab20fd;
    box-shadow: 3px 3px 1em #ab20fd;;
}
.overlay {
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .3;
    cursor: pointer;
}

.overlay:hover {
    opacity: .6;
}

.wording-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 50%;
    opacity: 1;
    z-index: 1;
    cursor: pointer;
    color: inherit;
}

.wording-overlay:hover ~ .overlay {
    opacity: .6;
}

.vending-items-container {
    position: absolute;
    width: 100vw;
    height: 92%;
    display: flex;
    align-items: center;
    justify-content: center;
}