*{
    box-sizing: border-box;
}

.aboutUsBackground {
    background-color: rgb(24, 24, 24);
    background-image: url("../../../public/Images/questions-background.jpeg");
    width: 100vw;
    background-position:fixed; 
    background-size: cover; 
    background-repeat: no-repeat; 
}

.subDescriptionContainer{
    width: 90%;
    min-height: 20vh;
    border-bottom: 2px solid black;
    padding-bottom: 2rem;
}

.descriptionContainer{
    display: flex;
    align-items: center;
}

@media screen and (max-width: 400px) {
    .subDescriptionContainer {
        width: 100%;
    } 
 }