.container-slide {
    width: 100%;
    height: 57vh;
    position: relative;
    overflow: hidden;
}

.slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 1.4s;
    background-color: black;
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.active-anim {
    opacity: 1;
}