.vendingItemscontainer {
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.4);
    z-index: 2;
}
.closeModalIcon {
    position: absolute;
    left: 0;
    font-size: 3.5rem;
}
@media screen and (max-width: 600px) {
    .closeModalIcon {
        font-size: 3.2rem;
    } 
 }

@media screen and (max-width: 500px) {
   .vendingItemsList {
        width: 85% !important;
    } 
}

@media screen and (max-width: 400px) {
    .closeModalIcon {
        top: 7%;
        left: -2%;
        font-size: 2.5rem;
    } 
 }

@media screen and (min-width: 1600px) {
    .vendingItemsList {
         width: 55% !important;
         font-size: 6rem !important;
     } 
 }
